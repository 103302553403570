import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"

const PrivacyPolicy = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Imprint"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Imprint"
            />
            <section className="privacy-policy-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="privacy-policy-content">
                                <p>
                                    visionar GmbH<br/>
                                    Mitterweg 16/II<br/>
                                    6020 Innsbruck<br/>
                                    Austria<br/>
                                    <a href={"https://www.visionar.com"}>www.visionar.com</a>
                                </p>
                                <br/>
                                <p>
                                    <b>Management:</b> Mag. Andreas Loidl<br/>
                                    <b>Commercial court:</b> Innsbruck<br/>
                                    <b>Company registration number:</b> 402718h<br/>
                                    <b>VAT number:</b> ATU68204588
                                </p>
                                
                                
                                <h3 style={{marginTop: '96px'}}>Copyright credits for images, graphics and animations:</h3>
                                <div style={{display: 'flex', marginTop: '64px'}}>
                                    <div className="col-lg-6 col-md-12">
                                        <h5>Website</h5>
                                        <p>
                                            ©visionar GmbH archive
                                            <br/>©apple.com
                                            <br/>©google.com
                                            <br/>©freepik.com / pikisuperstar
                                            <br/>©iconfinder.com / eucalypstudio
                                        </p>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <h5>Blog Posts</h5>
                                        <p>
                                            ©visionar GmbH archive
                                            <br/>©wikitude
                                            <br/>©apple.com
                                            <br/>©google.com
                                            <br/>©vuforia
                                            <br/>©Maxst
                                            <br/>©DeepAR
                                            <br/>©EasyAR
                                            <br/>©ARToolKit
                                            <br/>©Xzimg
                                            <br/>©ARS Electronica / vog.photo
                                            <br/>©AdobeStock / Sergey Nivens_138574614
                                            <br/>©pexels.com / bruce mars
                                            <br/>©pexels.com / Lee Campbell
                                            <br/>©unsplash.com / patrick_schneider
                                            <br/>©unsplash.com / joshuaearle
                                            <br/>©unsplash.com / anniespratt
                                            <br/>©pexels.com / Christina Morillo
                                        </p>
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default PrivacyPolicy;